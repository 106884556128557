body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.iseLcn, .dGQKmy  {
  font-size: 14px !important;
  background-color: rgb(237, 237, 237) !important;
}
.hIidFV, .bNSLrS {
  font-size: 14px !important;
}

.geJtFr{
  background-color: rgb(237, 237, 237) !important;
}
.css-i3xbtr-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,.css-1bf6xnj-MuiPaper-root-MuiPopover-paper-MuiMenu-paper
{

  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 4px !important;;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover
{
 background-color: #FF9933 !important;
 color: #fff;
} 
.removePerksFromlist{
  position: absolute;
    top: 8px;
    right: 15px;
    color: red;
}

.positionrelative{
  position: relative;
}
